@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));*/
}
.fork-btn img{
  height: 28px;
}
.show{
  display: block;
}
.hidden {
  display: none !important;
}

.dark a{ color: rgb(255, 255, 255);}

.light a{color: #000000;}
.dark {
  /*filter: invert(1) hue-rotate(180deg);*/
  text-align: center;
  background-color: #011233;
  color:rgb(255, 255, 255);
}
.dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255) !important;
}
.dark .project-card-view{
  background-color: #011233;
}
.light {
  background-color: #dcd8d857;
  color: #000000;
  text-align: center;
}
.light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}
.dark .project-card-view{
  background-color: #dcd8d857;
}
.card-dow{
 position: absolute; bottom:2px; left:0; right:0; ;
}
.pdfcss{
  
  height: 750px;
}
